<template>
  <VueEasyLightbox
    :visible="index !== null"
    :imgs="items"
    :index="index"
    @hide="index = null"
  >
  </VueEasyLightbox>
  <div class="flex flex-wrap overflow-hidden md:-mx-1 images-list-item">
    <img
      v-for="(img, key) in media"
      :key="key"
      :src="getThumbnail(img)"
      class="w-full overflow-hidden md:px-1 md:w-1/6 mt-4 cursor-pointer"
      @click="index = key"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useQuestionStore } from '@/store/modules/Question';
import Placeholder from '@/assets/images/video_placeholder.jpg';

const questionStore = useQuestionStore();

const index = ref(null);

const media = computed(() => questionStore.currentQuestion.inactiveMedia());
const items = computed(() => media.value.map((m) => m.base64Tag));

const getThumbnail = (img) => {
  if (img.fileType === 'video') {
    return Placeholder;
  }
  return img.base64Tag;
};
</script>

<style lang="scss" scoped>
img {
  @apply rounded;
  top: 0;
  position: relative;
  transition: top 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.images-list {
  border-radius: 0.375rem;
  margin-top: auto;
}
.images-list-item {
  align-items: flex-start;
  img:hover {
    top: -2px;
  }
}
</style>
