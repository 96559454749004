import axios from 'axios';
import router from '../router';
import { useAuthStore } from '@/store/Auth';
import { getRefreshtoken } from './auth';

const loginInterceptor = config => {
  const authStore = useAuthStore(); 
  if (authStore.isLoggedIn) {
    const token = authStore.token;
    const editLanguage = authStore.editLanguage;
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['Accept-Language'] = editLanguage;
    config.headers['Content-Language'] = editLanguage;
  }

  return config;
};

const userApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
});

userApi.interceptors.response.use(
  response => {
    // Return a successful response back to the calling service
    return response;
  },
  error => {
    const authStore = useAuthStore(); 
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    if (error.config.url.indexOf('token/refresh') !== -1) {
      // will error
      authStore.removeAuth(); 
      authStore.resetLoadingState(); 
      router.push({ name: 'login' });
      return Promise.reject(error);
    }
    // Logout user if token refresh didn't work or user is disabled
    // Try request again with new token
    return getRefreshtoken(authStore.refreshToken)
      .then(token => {
        // set token in store
        authStore.setToken(token.access);
        authStore.setRefreshToken(token.refresh);
        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${token.access}`;
        return axios.request(config);
      })
      .catch(error => {
        Promise.reject(error);
      });
  }
);

userApi.interceptors.request.use(loginInterceptor);

export { userApi, loginInterceptor };
