/**
 * Unpack the API data and log errors on development
 * @param {Promise} request
 * @return {Promise<*>}
 */
export const unpack = async request => {
  try {
    const { data } = await request;
    return data;
  } catch (e) {
    throw e;
  }
};

/**
 * capitalize the first letter of the word
 *
 * @param {String} the word
 * @returns {String} capitalized word
 */
export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
