function addTextInAllLanguagesForAllUsers(obj, payload) {
  const languages = ['', 'De', 'Fr', 'It', 'En'];
  const roles = ['', 'Expert', 'Lector'];
  let field = '';
  languages.forEach(lang => {
    roles.forEach(role => {
      // lector should not be able to edit the german field
      if (!(lang === 'De' && role === 'Lector')) {
        field = `text${role}${lang}`;
        obj[field] = payload && payload[field] ? payload[field] : '';
      }
    });
  });
}

class Answer {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    addTextInAllLanguagesForAllUsers(this, payload);
    this.isCorrect = payload && payload.isCorrect;
  }
}

class Category {
  constructor(payload) {
    this.name = payload && payload.name ? payload.name : '';
  }
}

class QuestionLight {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    this.nr = payload && payload.nr ? payload.nr : null;
    this.text = payload && payload.text ? payload.text : '';
    this.status = payload && payload.status ? payload.status : '';
    this.ruleSet = payload && payload.ruleSet ? payload.ruleSet : '';
    this.getStatusDisplay =
      payload && payload.getStatusDisplay ? payload.getStatusDisplay : '';
    this.categories =
      payload && payload.categories
        ? payload.categories.map(category => {
            return new Category(category);
          })
        : '';
    this.division = payload && payload.division ? payload.division : null;
    this.areaOfExpertise =
      payload && payload.areaOfExpertise ? payload.areaOfExpertise : null;
  }

  listOfCategories() {
    return this.categories.map(category => category.name).join(', ');
  }
}

class Author {
  constructor(payload) {
    this.firstName = payload && payload.firstName ? payload.firstName : '';
    this.lastName = payload && payload.lastName ? payload.lastName : '';
  }
}

class Comment {
  constructor(payload) {
    this.author = payload && payload.author ? new Author(payload.author) : null;
    this.message = payload && payload.message ? payload.message : '';
    this.createdAt = payload && payload.createdAt ? payload.createdAt : '';
  }
}

class Chapter {
  constructor(payload) {
    this.digit = payload && payload.digit ? payload.digit : '';
    this.title = payload && payload.title ? payload.title : '';
  }
}

class QuestionExtended {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    this.nr = payload && payload.nr ? payload.nr : null;

    addTextInAllLanguagesForAllUsers(this, payload);

    this.ruleSet = payload && payload.ruleSet ? payload.ruleSet : '';
    this.ruleSetExpert =
      payload && payload.ruleSetExpert ? payload.ruleSetExpert : '';
    this.status = payload && payload.status ? payload.status : 'new';
    this.isTranslatedFr =
      payload && payload.isTranslatedFr ? payload.isTranslatedFr : false;
    this.isTranslatedIt =
      payload && payload.isTranslatedIt ? payload.isTranslatedIt : false;
    this.isTranslatedEn =
      payload && payload.isTranslatedEn ? payload.isTranslatedEn : false;
    this.getStatusDisplay =
      payload && payload.getStatusDisplay ? payload.getStatusDisplay : '';
    this.categories =
      payload && payload.categories
        ? payload.categories.map(category => {
            return new Category(category);
          })
        : '';
    this.chapter =
      payload && payload.chapter ? new Chapter(payload.chapter) : null;
    this.division = payload && payload.division ? payload.division : null;
    this.questionMedia = payload.questionMedia;
    this.comments =
      payload && payload.comments
        ? payload.comments.map(comment => {
            return new Comment(comment);
          })
        : [];
    this.areaOfExpertise =
      payload && payload.areaOfExpertise ? payload.areaOfExpertise : null;

    if (payload && payload.answers) {
      this.answers =
        payload.answers.map(data => {
          return new Answer(data);
        }) || [];
    }
    this.learnApplication =
      payload && payload.learnApplication ? payload.learnApplication : false;
  }

  listOfCategories() {
    return this.categories.map(category => category.name).join(', ');
  }

  activeMedia() {
    return this.questionMedia.find(m => m.active);
  }

  inactiveMedia() {
    return this.questionMedia.filter(m => !m.active);
  }

  inWorkgroupReview() {
    return this.status === 'workgroup';
  }
}
export { QuestionLight, QuestionExtended };
