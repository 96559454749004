<template>
  <div class="bg-white rounded p-4">
    <div class="boxhead flex justify-between items-center">
      <div class="personinformation flex items-center">
        <div
          class="avatar uppercase bg-gray-500 flex w-10 h-10 items-center justify-center rounded-full font-bold text-sm mr-6"
        >
          {{ authorInitials }}
        </div>
        <span class="capitalize font-medium text-black text-sm">
          {{ comment.author.firstName }} {{ comment.author.lastName }}
        </span>
      </div>
      <time class="time font-bold text-sm text-black mr-4">
        {{ showDateOrTime(new Date(comment.createdAt)) }}
      </time>
    </div>

    <div
      class="commentbox ml-16 mr-4 min-h-20 items-center text-sm font-light mb-3 text-black"
    >
      <p class="break-normal">
        {{ comment.message }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { showDateOrTime } from '@/filters/date.js';

const props = defineProps({
  comment: {
    type: Object,
    required: true,
  },
});

const authorInitials = computed(() => {
  return `${props.comment.author.firstName.charAt(
    0
  )}${props.comment.author.lastName.charAt(0)}`;
});
</script>

<style lang="postcss" scoped></style>
