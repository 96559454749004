<template>
  <div>
    <Modal :is-open="modalOpen" @closeModal="closeModal">
      <template #header>
        <h2>{{ $t('questions.questionsEdit.switchLanguageModal.title') }}</h2>
      </template>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('questions.questionsEdit.switchLanguageModal.text')"></p>

      <template #footer>
        <button class="btn" @click.prevent="closeModal">
          {{ $t('questions.questionsEdit.switchLanguageModal.dismiss') }}
        </button>
        <button class="btn btn--primary" @click.prevent="languageSwitch">
          {{ $t('questions.questionsEdit.switchLanguageModal.accept') }}
        </button>
      </template>
    </Modal>

    <div class="flex items-center">
      <span class="mr-2">{{ $t('questions.questionsEdit.lang') }}:</span>
      <multiselect
        v-model="lang"
        :options="availableLanguages"
        :searchable="false"
        :allow-empty="false"
        :show-labels="false"
        :custom-label="(l) => $t(`questions.questionsEdit.language.${l}`)"
        @select="modalOpen = true"
      >
      </multiselect>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useAuthStore } from '@/store/Auth';
import { useQuestionStore } from '@/store/modules/Question';
import { toaster } from '@/globals/toaster.js';
import Modal from '@/components/app/Modal.vue';

const authStore = useAuthStore();
const questionStore = useQuestionStore();

const modalOpen = ref(false);
const lang = ref('');

const availableLanguages = computed(() => ['de', 'fr', 'it', 'en']);

onBeforeMount(() => {
  lang.value = authStore.editLanguage;
});

const languageSwitch = async () => {
  modalOpen.value = false;
  authStore.changeEditLanguage(lang.value);
  try {
    await questionStore.getQuestion(questionStore.currentQuestion.id);
  } catch (e) {
    toaster('error', e.message);
  }
};

const closeModal = () => {
  // close modal and reset language dropdown to the old language
  modalOpen.value = false;
  lang.value = authStore.editLanguage;
};
</script>