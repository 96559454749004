<template>
  <div class="flex flex-wrap overflow-hidden md:-mx-3">
    <div class="w-full overflow-hidden md:px-3 md:w-1/2 mb-4">
      <div class="bg-white rounded p-4">
        <h3
          class="text-sm text-black uppercase font-medium border-b border-gray-800 pb-1 mb-3"
        >
          {{ $t('questions.questionsEdit.yourComment') }}
        </h3>
        <div class="w-full" :class="commentBoxActiveClasses">
          <div class="flex items-start mb-3">
            <textarea
              v-model="form.message"
              :placeholder="$t('questions.questionsEdit.addComment')"
              :class="commentActiveClasses"
              type="text"
            />
          </div>
          <div class="flex justify-end">
            <button
              class="btn btn--primary"
              :disabled="commentButtonDisabled"
              @click="postComment"
            >
              {{ $t('questions.questionsEdit.addComment') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full overflow-hidden md:px-3 md:w-1/2 mb-4">
      <Comment
        v-for="(comment, key) in question.comments"
        :key="key"
        class="mb-6"
        :comment="comment"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/store/Auth';
import { useQuestionStore } from '@/store/modules/Question';
import { toaster } from '@/globals/toaster.js';
import Comment from '@/components/question/Comment.vue';

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const authStore = useAuthStore();
const questionStore = useQuestionStore();

const form = ref({
  message: '',
});

const commentButtonDisabled = computed(() => {
  return form.value.message === '';
});

const commentActiveClasses = computed(() => {
  if (form.value.message.length) {
    return 'form-textarea align-middle w-full mt-4 mb-4';
  } else {
    return 'form-textarea align-middle w-full mt-4 mb-4';
  }
});

const commentBoxActiveClasses = computed(() => {
  if (form.value.message.length) {
    return 'commentbox boxhead items-center mb-6';
  } else {
    return 'boxhead commentbox items-center mb-6';
  }
});

const postComment = () => {
  questionStore.createComment({
    author: authStore.user.id,
    question: props.question.id,
    message: form.value.message,
  });
  form.value.message = '';
  toaster('success', t('questions.questionsEdit.addCommentSuccessfull'));
};
</script>

<style lang="scss" scoped></style>
