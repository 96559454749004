<template>
  <DefaultLayout>
    <div class="flex flex-wrap p-16 pt-0">
      <div class="w-full">
        <h1 class="mb-10">
          {{ $t('licensee.title') }}
        </h1>
      </div>
      <div class="w-full pr-8 relative">
        <LicenceUpdatesTable :updates="authStore.user.updates" />
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup>
import { useAuthStore } from '@/store/Auth';
import LicenceUpdatesTable from '@/components/licensee/LicenceUpdatesTable.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const authStore = useAuthStore();
</script>
