<template>
  <div>
    <BackToList />

    <!-- TITLE -->
    <h1 class="mb-6">
      {{ $t('general.question') }} {{ $t('general.nmbr') }}
      {{ currentQuestion.nr }}
    </h1>

    <!-- META/IMAGE SECTION -->
    <Meta :question="currentQuestion" />

    <!-- QUESTION / RULE SET SECTION -->
    <div class="flex justify-between mt-16 mb-4">
      <h2 class="">
        {{ $t('questions.questionsEdit.titleQuestion') }}
      </h2>
      <EditLanguageSwitcher />
    </div>

    <div v-if="user && user.showEditLanguageInfo(editLanguage)" class="mb-6">
      <EditLanguageInfo />
    </div>

    <div class="flex flex-wrap overflow-hidden md:-mx-3">
      <div class="w-full overflow-hidden md:px-3 md:w-1/2">
        <div class="bg-white rounded p-6 h-full">
          <QuestionForm
            :key="`question_${currentQuestion.id}_${editLanguage}`"
            v-model="updates.text"
            :question="currentQuestion"
            :show-translation="showTranslation"
            :value-translated="currentQuestion[`text${editLangCapitalized}`]"
          />
        </div>
      </div>

      <div class="w-full overflow-hidden md:px-3 md:w-1/2">
        <div class="attribute-list bg-white rounded p-6 h-full">
          <h3
            class="text-sm text-black uppercase font-medium border-b border-gray-800 pb-1 mb-3"
          >
            {{ $t('questions.questionsEdit.ruleSet') }}
          </h3>
          <div class="text-black text-sm">{{ currentQuestion.ruleSet }}</div>

          <RuleSetForm
            v-if="user.isExpert"
            :key="`ruleSet_${currentQuestion.id}_${editLanguage}`"
            v-model="updates.ruleSet"
            :question="currentQuestion"
          />
        </div>
      </div>
    </div>

    <!-- ANSWER SECTION -->
    <div class="answers w-full">
      <h2 class="mt-16 mb-4">
        {{ $t('questions.questionsEdit.titleAnswers') }}
      </h2>
      <div class="flex">
        <AnswerForm
          v-for="n in currentQuestion.answers.length"
          :key="`${currentQuestion.answers[n - 1].id}_${editLanguage}`"
          v-model="updates.answers[n - 1].text"
          :answer="currentQuestion.answers[n - 1]"
          :question="currentQuestion"
          :show-translation="showTranslation"
          :value-translated="
            currentQuestion.answers[n - 1][`text${editLangCapitalized}`]
          "
        />
      </div>
    </div>

    <!-- CONTROLS ASTRA -->
    <div v-if="user.isAstra" class="mt-6">
      <h2 class="mt-16 mb-4">
        {{ $t('questions.questionsEdit.titleAstra') }}
      </h2>
      <QuestionDecision decision-by="astra" />
    </div>

    <!-- CONTROLS Workgroup -->
    <div v-else-if="currentQuestion.inWorkgroupReview()" class="mt-6">
      <h2 class="mt-16 mb-4">
        {{ $t('questions.questionsEdit.titleWorkgroup') }}
      </h2>
      <QuestionDecision decision-by="workgroup" />
    </div>

    <!-- CONTROLS ASA -->
    <div v-else class="flex items-center justify-end mt-8 mb-16">
      <button
        class="btn btn--outline btn--big mr-5"
        @click.prevent="save(false)"
      >
        {{ $t('questions.questionsEdit.saveAsDraft') }}
      </button>
      <Commit @commit="save(true)" />
    </div>

    <!-- COMMENTS -->
    <div
      v-if="user.hasPermission('viewComment')"
      class="flex flex-col pb-16 border-t-2 border-gray-800"
    >
      <h2 class="mt-16 mb-4">
        {{ $t('questions.questionsEdit.comment') }}
      </h2>
      <CommentList :question="currentQuestion" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/store/Auth';
import { useQuestionStore } from '@/store/modules/Question';
import { useLoadingStore } from '@/store/modules/Loading';
import { toaster } from '@/globals/toaster.js';
import AnswerForm from '@/components/question/AnswerForm.vue';
import BackToList from '@/components/question/BackToList.vue';
import CommentList from '@/components/question/CommentList.vue';
import Commit from '@/components/question/Commit.vue';
import EditLanguageInfo from '@/components/question/EditLanguageInfo.vue';
import EditLanguageSwitcher from '@/components/question/EditLanguageSwitcher.vue';
import Meta from '@/components/question/Meta.vue';
import QuestionDecision from '@/components/question/QuestionDecision.vue';
import QuestionForm from '@/components/question/QuestionForm.vue';
import RuleSetForm from '@/components/question/RuleSetForm.vue';

const router = useRouter();
const { t } = useI18n();

const authStore = useAuthStore();
const questionStore = useQuestionStore();
const loadingStore = useLoadingStore();

const updates = computed(() => {
  let answers = [];

  questionStore.currentQuestion.answers.forEach((answer) => {
    answers.push({
      id: answer.id,
      text: answer[`text${authStore.user.role}`],
    });
  });

  return {
    text: questionStore.currentQuestion[`text${authStore.user.role}`],
    ruleSet: questionStore.currentQuestion[`ruleSetExpert`],
    answers,
  };
});

const user = computed(() => authStore.user)
const editLanguage = computed(() => authStore.editLanguage)
const currentQuestion = computed(() => questionStore.currentQuestion)

const editLangCapitalized = computed(() => {
  return `${authStore.editLanguage
    .charAt(0)
    .toUpperCase()}${authStore.editLanguage.slice(1)}`;
});

const showTranslation = computed(() => {
  if (authStore.editLanguage === 'de') {
    return false;
  }
  return (
    authStore.user.isLector ||
    authStore.user.isExpert ||
    questionStore.currentQuestion.inWorkgroupReview()
  );
});

const save = async (commit) => {
  loadingStore.addLoadingState();
  // experts and lectors have an own field to store the data to.
  // These fields are postFixed with 'Expert' and 'Lector', so we map them here
  const patch = {
    id: questionStore.currentQuestion.id,
    answers: [],
  };

  const textKey = `text${authStore.user.role}${editLangCapitalized.value}`;
  const ruleSetKey = `ruleSet${authStore.user.role}`;

  patch[textKey] = updates.value.text;
  patch[ruleSetKey] = updates.value.ruleSet;

  updates.value.answers.forEach((answer) => {
    let answerUpdate = { id: answer.id };
    answerUpdate[textKey] = answer.text;
    patch.answers.push(answerUpdate);
  });

  try {
    if (commit) {
      await questionStore.commitQuestion(patch);
      router.push({
        name: 'questionList',
      });
    } else {
      await questionStore.saveQuestion(patch);
      toaster('success', t('questions.questionsEdit.stored'));
    }
  } catch (e) {
    toaster('error', e.message);
  }

  loadingStore.removeLoadingState();
};
</script>
