import { unpack } from './helpers';
import { userApi } from './api';
import { QuestionLight, QuestionExtended } from '../models/Question.model';

export const saveQuestion = async questionExtended => {
  const response = await unpack(
    userApi.patch(`/question/${questionExtended.id}/`, questionExtended)
  );
  return new QuestionExtended(response);
};

export const commitQuestion = async questionExtended => {
  await userApi.put(
    `/question/${questionExtended.id}/?commit=true`,
    questionExtended
  );
};

export const loadQuestion = async id => {
  const response = await unpack(userApi.get(`/question/${id}/`));
  return new QuestionExtended(response);
};

export const loadQuestions = async () => {
  const response = await unpack(userApi.get('/question/'));
  return {
    questions: response.map(payload => {
      return new QuestionLight(payload);
    }),
  };
};

export const postComment = async comment => {
  const response = await unpack(userApi.post('/comment/', comment));
  return response.id;
};

export const approveQuestion = async (approval, id, comment, decisionBy) => {
  const payload = {
    id: id,
    isApproved: approval,
    approvalBy: decisionBy,
    comment: comment ? comment : null,
  };

  await unpack(userApi.put(`/question/${id}/approve/`, payload));
};
