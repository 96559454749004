<template>
  <v-table
    :data="questions"
    class="bg-transparent border-none shadow-none question-table"
  >
    <template #head>
      <!-- number -->
      <v-th sort-key="id" class="filterable-header font-medium">
        <span>{{
          $t('questions.questions.number')
        }}</span>
      </v-th>

      <!-- text -->
      <v-th sort-key="text" class="filterable-header font-medium">
        <span>{{ $t('questions.questions.text') }}</span>
      </v-th>

      <!-- category -->
      <v-th sort-key="categories" class="filterable-header font-medium">
        <span>{{
          $t('questions.questions.category')
        }}</span>
      </v-th>

      <!-- areaOfExpertise -->
      <!-- <v-th sort-key="areaOfExpertise" class="filterable-header">
        <span>{{ $t('questions.questions.areaOfExpertise') }}</span>
      </v-th> -->

      <!-- state -->
      <v-th sort-key="status" class="filterable-header font-medium">
        <span>{{
          $t('questions.questions.status')
        }}</span>
      </v-th>

      <!-- TODO: must this be sortable? -->
      <th class="font-medium">
        <span>{{
          $t('questions.questions.ruleSet')
        }}</span>
      </th>
    </template>

    <template #body="{ rows }">
      <tr
        v-for="row in rows"
        :key="row.id"
        class="cursor-pointer"
        @click="goTo(row)"
      >
        <!-- <td class="pl-5">-->
        <!-- <label class="sr-only" for="`checked-row-${row.id}`">-->
        <!-- {{ $t('general.checkTheLine') }}-->
        <!-- </label>-->
        <!-- <input-->
        <!-- :id="`checked-row-${row.id}`"-->
        <!-- v-model="row.checked"-->
        <!-- class="transform scale-125"-->
        <!-- type="checkbox"-->
        <!-- />-->
        <!-- </td>-->
        <td class="pl-5 text-white font-bold">{{ row.nr }}</td>
        <td>
          <div>{{ row.text }}</div>
        </td>

        <td>{{ row.listOfCategories() }}</td>
        <!-- <td>{{ row.areaOfExpertise }}</td> -->

        <!-- State -->
        <td>
          {{ row.getStatusDisplay }}
        </td>
        <td>
          {{ row.ruleSet }}
        </td>
      </tr>
    </template>
  </v-table>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/Auth';

defineProps({
  questions: {
    type: Array,
    required: true,
  },
});

const router = useRouter();

const authStore = useAuthStore();

const goTo = (row) => {
  // We currently do not have a frontend edit view for the admin
  // so we send him to backend, while translators, editors and so on will
  // work in the frontend. This may change in the future, but for now
  // this is the workflow.
  if (authStore.user.isAdmin) {
    window.open(
      import.meta.env.VITE_BACKEND_URL +
        '/admin/theoryQuestions/question/' +
        row.id +
        '/',
      '_blank'
    );
    return;
  }
  router.push({
    name: 'questionDetail',
    params: {
      id: row.id,
    },
  });
};
</script>

<style lang="postcss" scoped>
.question-table {
  border-collapse: separate;
  border-spacing: 0 1rem;

  & th {
    font-size: 0.75rem;
    color: #000;
  }

  & tr {
    border-radius: 0.75rem;
  }

  & td {
    background-color: #fff;
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    color: #000;
    height: 52px;

    &:first-child {
      @apply bg-red-700;
      width: 8%;
      color: #fff;
    }

    &:nth-child(2) {
      width: 40%;
    }

    /* &:nth-child(2) div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }  */

    &:nth-child(3) {
      width: 15%;
      max-width: 15%;
    }

    &:last-child {
      width: 15%;
      max-width: 15%;
    }
  }

  & tr {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0),
      0 1px 2px rgba(0, 0, 0, 0);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: scale(1);
  }

  & tr:hover {
    box-shadow:
      0 0 8px 0 rgba(0, 0, 0, 0.08),
      0 0 15px 0 rgba(0, 0, 0, 0.02),
      0 0 20px 4px rgba(0, 0, 0, 0.06);
  }

  & tr td:first-child {
    border-top-left-radius: 0.375rem;
  }

  & tr td:last-child {
    border-top-right-radius: 0.375rem;
  }

  & tr td:first-child {
    border-bottom-left-radius: 0.375rem;
  }

  & tr td:last-child {
    border-bottom-right-radius: 0.375rem;
  }
}

.filterable-header {
  @apply border-none items-center cursor-pointer select-none;
  letter-spacing: 0.15px;

  & span {
    @apply inline-block float-left mr-2;
  }

  & :deep(svg) {
    @apply inline-block fill-current;
    margin-top: -3px;
    @apply text-primary;
  }
}
</style>
