import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    loadingList: [],
  }),
  
  getters: {
    loading: (state) => {
      return state.loadingList.length !== 0;
    },
  },

  actions: {
    addLoadingState() {
      this.loadingList.push('loading');
    },
    removeLoadingState() {
      this.loadingList.pop();
    },
    resetLoadingState() {
      this.loadingList = [];
    },
  },
});