<template>
  <div class="attribute-list flex flex-wrap overflow-hidden md:-mx-2">
    <dl class="w-full overflow-hidden md:px-2 md:w-1/2 mb-4">
      <div class="h-full bg-white pt-2 pr-4 pl-4 pb-6 rounded">
        <dd class="border-b border-gray-800 pb-1">
          {{ $t('questions.questions.categories') }}
        </dd>
        <dt>{{ question.listOfCategories() }}</dt>
      </div>
    </dl>

    <dl class="w-full overflow-hidden md:px-2 md:w-1/2 mb-4">
      <div class="h-full bg-white pt-2 pr-4 pl-4 pb-6 rounded">
        <dd class="border-b border-gray-800 pb-1">
          {{ $t('questions.questions.topic') }}
        </dd>
        <dt>
          {{ question.areaOfExpertise }}
        </dt>
      </div>
    </dl>

    <!-- TODO: still needed?
    <dl v-if="isASA" class="mb-8">
      <div class="bg-white p-4 pb-6 rounded" >
        <dd class="border-b border-gray-500 pb-1">{{ $t('questions.questionsEdit.ruleSet') }}</dd>
        <dt>{{ question.ruleSet }}</dt>
      </div>
    </dl> -->

    <!-- <dl v-if="isVKS" class="mb-8">
      <div class="bg-white p-4 pb-6 rounded">
        <dd class="border-b border-gray-500 pb-1">{{ $t('questions.questionsEdit.chapter') }}</dd>
        <dt>{{ question.chapter.digit }}: {{ question.chapter.title }}</dt>
      </div>
    </dl> -->

    <dl v-if="isVKS" class="w-full overflow-hidden md:px-2 md:w-1/2 mb-4">
      <div class="h-full bg-white pt-2 pr-4 pl-4 pb-6 rounded">
        <dd class="border-b border-gray-800 pb-1">
          {{ $t('questions.questionsEdit.learnApplication') }}
        </dd>
        <dt>{{ availableInLearnApplication }}</dt>
      </div>
    </dl>

    <dl class="w-full overflow-hidden md:px-2 md:w-1/2 h-full mb-4">
      <div class="bg-white pt-2 pr-4 pl-4 pb-6 rounded">
        <dd class="border-b border-gray-800 pb-1">
          {{ $t('questions.questions.status') }}
        </dd>
        <dt>
          {{ question.getStatusDisplay }}
        </dt>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { QuestionExtended } from '@/models/Question.model';

const props = defineProps({
  question: {
    type: QuestionExtended,
    required: true,
  },
});

const { t } = useI18n();

const isASA = computed(() => import.meta.env.VITE_FRONTEND_TAG == 'ASA');
const isVKS = computed(() => import.meta.env.VITE_FRONTEND_TAG == 'VKS');

const availableInLearnApplication = computed(() => {
  return props.question.learnApplication ? t('general.yes') : t('general.no');
});
</script>

<style lang="postcss" scoped>
:deep(label) > span {
  @apply font-bold inline-block text-base mb-6;
}

.attribute-list {
  & dd {
    @apply text-sm font-medium text-left mb-3 uppercase;
  }

  & dt {
    @apply mb-0 font-light text-sm;
  }
}
</style>
