<template>
  <div id="app">
    <LoadingSpinner v-if="loading" />
    <router-view />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useLoadingStore } from '@/store/modules/Loading';
import LoadingSpinner from '@/components/app/LoadingSpinner.vue';

const loadingStore = useLoadingStore();
const loading = computed(() => loadingStore.loading);
</script>

<style>
#app {
  height: 100vh;
}
</style>
