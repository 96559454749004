<template>
  <div class="loading">
    <div>
      <div>
        <svg-icon
          class="inline-block w-3 svg-icon svg-icon--chevron-left"
          name="steering-wheel"
        />
      </div>
    </div>
  </div>
</template>
