<template>
  <DefaultLayout>
    <div class="ml-4 mr-4 md:ml-8 md:mr-8 xl:ml-16 xl:mr-16 relative">
      <Detail v-if="currentQuestion" :key="`${editFormKey}_${editLanguage}`" />
    </div>
  </DefaultLayout>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/store/Auth';
import { useQuestionStore } from '@/store/modules/Question';
import { useLoadingStore } from '@/store/modules/Loading';
import Detail from '@/components/question/Detail.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const router = useRouter();
const route = useRoute();

const loadingStore = useLoadingStore();
const authStore = useAuthStore();
const questionStore = useQuestionStore();

const editFormKey = computed(() => {
  // This is used to autorefresh the view whenever a new comment was made
  return (
    questionStore.currentQuestion.comments.map((c) => c.message).join('') +
    questionStore.currentQuestion.text +
    questionStore.currentQuestion.ruleSet +
    questionStore.currentQuestion.answers.map((a) => a.text).join('')
  );
});

const currentQuestion = computed(() => questionStore.currentQuestion);
const editLanguage = computed(() => authStore.editLanguage);

onMounted(async () => {
  loadingStore.addLoadingState();
  try {
    authStore.changeEditLanguage(authStore.user.language);
    await questionStore.getQuestion(route.params.id);
  } catch (e) {
    await router.push({ name: 'questionList' });
  }
  loadingStore.removeLoadingState();
});

onUnmounted(async () => {
  // Reset the edit language to German to make sure all future requests will be done in German
  // Especially the request to get the list of all questions
  authStore.changeEditLanguage('de');
  await questionStore.unsetQuestion();
});
</script>
