import { createApp, Fragment, h } from 'vue';
import App from './App.vue';
import router from './router/';
import i18n from './i18n';
import { createPinia } from 'pinia';

// Styles
import './assets/css/main.postcss';

// Plugins
import Vue3Toastify from 'vue3-toastify';
import SmartTable from 'vuejs-smart-table';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect';
import VueEasyLightbox from 'vue-easy-lightbox';

// Global components
import SvgIcon from '@/components/app/SvgIcon.vue';

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;
  app.use(router);
  app.use(i18n);
  app.use(createPinia());

  // Plugins
  app.use(Vue3Toastify);
  app.use(SmartTable);
  app.component('multiselect', Multiselect);
  app.component('VueEasyLightbox', VueEasyLightbox);

  // Global components
  app.component('SvgIcon', SvgIcon);

  app.mount('#app');
};

// Initiate app with VueAxe if in development mode
if (process.env.NODE_ENV === 'development') {
  import('vue-axe').then((res) => {
    const VueAxe = res.default;
    const VueAxePopup = res.VueAxePopup;
    app = createApp({
      render: () => h(Fragment, [h(App), h(VueAxePopup)]),
    });
    app.use(VueAxe, {
      // clears the console after render
      clearConsoleOnUpdate: false,
      config: {},
    });
    setupApp(app);
  });
} else {
  app = createApp(App);
  setupApp(app);
}
