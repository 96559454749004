<template>
  <label :class="['block', { 'cursor-not-allowed': disabled }, classLabel]">
    <span
      :class="[
        { 'sr-only': labelHidden },
        { block: isBlock },
        { 'text-lg font-normal text-gray-800 text-left mb-3': !isBlock },
        classLabel,
      ]"
    >
      {{ label }}
    </span>
    <textarea
      v-model="localValue"
      :class="['form-textarea', 'align-middle', 'w-full', classTextarea]"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
    ></textarea>
  </label>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  labelHidden: {
    type: Boolean,
    required: false,
  },
  isBlock: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  classTextarea: {
    type: String,
    default: '',
  },
  classLabel: {
    type: String,
    default: '',
  },
  rows: {
    type: Number,
    default: 3,
  },
})

const localValue = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

</script>
