<template>
  <DefaultLayout>
    <div class="flex flex-wrap p-16 pt-0">
      <div class="w-full">
        <!-- <span class="h1">{{ $t('general.welcome') }} {{ getName }} !</span> -->
        <h1 class="mb-10">
          {{ $t('questions.questions.title') }}
        </h1>
      </div>
      <div class="w-full pr-8 relative">
        <QuestionsTable
          v-if="questionStore.questions.length"
          :questions="questionStore.questions"
        />

        <div v-else>
          {{ $t('questions.questions.noData') }}
        </div>
        <br />
      </div>

      <!-- <div class="w-full md:w-1/4 bg-white p-4 sidebar-right flex flex-col">
        <div
          class="sidebar-right__footer text-center border-t border-gray-500 pt-6 pb-2"
        >
          <a href="#" class="text-red-900">Alle Filter zurücksetzen</a>
        </div>
      </div> -->
    </div>
  </DefaultLayout>
</template>

<script setup>
import { onMounted } from 'vue';
import { useQuestionStore } from '@/store/modules/Question';
import { useLoadingStore } from '@/store/modules/Loading';
import QuestionsTable from '@/components/question/QuestionsTable.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const loadingStore = useLoadingStore();
const questionStore = useQuestionStore();

onMounted(async () => {
  loadingStore.addLoadingState();
  await questionStore.getQuestions();
  loadingStore.removeLoadingState();
});
</script>

<style lang="postcss" scoped>
.sidebar-right {
  margin-top: 3.25rem;
  border-radius: 0.375rem;
}

.sidebar-right__footer {
  margin-top: auto;
}
</style>
