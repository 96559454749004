<template>
  <div>
    <Modal :is-open="modalOpen" @closeModal="modalOpen = false">
      <template #header>
        <h2>{{ $t('questions.questionsEdit.modal.title') }}</h2>
      </template>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('questions.questionsEdit.modal.text')"></p>

      <template #footer>
        <button
          class="btn btn--link flex items-center"
          @click.prevent="modalOpen = false"
        >
          <svg-icon
            class="inline-block w-5 h-5 svg-icon svg-icon--chevron-left svg-icon svg-icon--times mr-1"
            name="times"
          />
          {{ $t('questions.questionsEdit.modal.dismiss') }}
        </button>
        <button class="btn btn--primary" @click.prevent="commit">
          {{ $t('questions.questionsEdit.modal.accept') }}
        </button>
      </template>
    </Modal>
    <button class="btn btn--primary btn--big" @click.prevent="modalOpen = true">
      {{ $t('questions.questionsEdit.save') }}
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Modal from '@/components/app/Modal.vue';

const emits = defineEmits(['commit']);

const modalOpen = ref(false);

const commit = () => {
  modalOpen.value = false;
  emits('commit');
}

</script>
