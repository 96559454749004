import axios from 'axios';
import { loginInterceptor } from '@/services/api';

export const downloadZip = async (url, filename) => {
  const backend = axios.create({
    baseURL: `${import.meta.env.VITE_BACKEND_URL}`,
  });

  // the request to the static media file will be intercepted from the backend to check the
  // permissions to the file, so we have to send the login information
  backend.interceptors.request.use(loginInterceptor);

  try {
    await backend
      .get(url, {
        responseType: 'blob',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
  } catch (e) {
    return Promise.reject(e);
  }
};
