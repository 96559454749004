<template>
  <div class="w-full h-full flex flex-col">
    <div>
      <h3
        class="text-sm text-black uppercase font-medium border-b border-gray-800 pb-1 mb-3"
      >
        {{ $t('questions.questionsEdit.question') }}
      </h3>
    </div>
    <div class="text-black text-sm">{{ question.textDe }}</div>

    <!-- TRANSLATION -->
    <div v-if="showTranslation">
      <div class="block text-black mt-4 font-medium">
        {{ $t('questions.questionsEdit.translation') }}
      </div>
      <div class="text-black text-sm">{{ valueTranslated }}</div>
    </div>

    <div class="textarea-container text-sm font-medium">
      <span v-if="user.isTranslator" class="block text-black mt-4">{{
        $t('questions.questionsEdit.translation')
      }}</span>
      <span v-else class="block text-black mt-4">{{
        $t('questions.questionsEdit.changes')
      }}</span>
      <Textarea
        v-model="content"
        :value="content"
        :disabled="disabled"
        label=""
        required
        @input="updateInput"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/store/Auth';
import Textarea from '@/components/app/Textarea.vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  valueTranslated: {
    type: String,
    required: true,
  },
  showTranslation: {
    type: Boolean,
    required: false,
  },
});

const authStore = useAuthStore();

const content = ref(props.modelValue);

const user = computed(() => authStore.user);

const disabled = computed(() => {
  return (
    !authStore.user.hasPermission('editQuestion', authStore.editLanguage) ||
    props.question.inWorkgroupReview()
  );
});

const updateInput = () => {
  emits('update:modelValue', content.value);
};
</script>

<style lang="postcss" scoped>
.textarea-container {
  margin-top: auto;
}
.block {
  @apply pt-2;
}
</style>
