import { defineStore } from 'pinia';
import {
  loadQuestion,
  loadQuestions,
  saveQuestion,
  commitQuestion,
  postComment,
} from '@/services/Questions.service';

export const useQuestionStore = defineStore('question', {
  state: () => ({
    questions: [],
    currentQuestion: null,
  }),

  actions: {
    unsetQuestion() {
      this.currentQuestion = null;
    },
    async getQuestion(id) {
      const loadedQuestion = await loadQuestion(id);
      this.currentQuestion = loadedQuestion;
    },
    async saveQuestion(question) {
      const savedQuestion = await saveQuestion(question);
      this.currentQuestion = savedQuestion;
    },
    async commitQuestion(question) {
      await commitQuestion(question);
      this.currentQuestion = null;
    },
    async getQuestions() {
      const response = await loadQuestions();
      this.questions = response.questions;
    },
    async createComment(comment) {
      const newComment = await postComment(comment);
      this.getQuestion(this.currentQuestion.id);
      return newComment;
    },
  },
});
