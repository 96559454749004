<template>
  <router-link
    :to="{ name: 'questionList' }"
    class="inline-block mb-8 text-red-900 no-underline back-link hover:underline"
  >
    <svg-icon
      class="inline-block w-3 svg-icon svg-icon--chevron-left"
      name="chevron-left"
    />
    {{ $t('general.backToOverView') }}
  </router-link>
</template>

<style lang="scss">
  .back-link {
    @apply text-red-900;
    padding-left: 1rem;

    svg {
      top: 0.23rem;
      left: 0;
      position: absolute;
      transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1)
    }

    &:hover svg {
      left: -0.15rem
    }
  }
</style>