import { Update } from '@/models/Update.model';
import { useAuthStore } from '@/store/Auth';
class User {
  constructor(payload) {
    if (!payload) return;
    this.id = payload.id || null;
    this.email = payload.email || null;
    this.firstName = payload.firstName || null;
    this.lastName = payload.lastName || null;
    this.updates = payload.updates || null;
    this.isAdmin = payload.isAdmin || false;
    this.isAstra = payload.isAstra || false;
    this.isExpert = payload.isExpert || false;
    this.isLector = payload.isLector || false;
    this.isTranslator = payload.isTranslator || false;
    this.language = payload.language || null;
    this.updates = payload.updates.map(u => new Update(u)) || [];

    this.role = this.isExpert ? 'Expert' : this.isLector ? 'Lector' : '';
  }

  isLicensee() {
    return this.updates.length() > 0;
  }

  // Show a notification to translators and lectors if they select a language other
  // than their language to let them know they can not edit this language
  showEditLanguageInfo(editLanguage) {
    if (this.isTranslator || this.isLector) {
      return this.language !== editLanguage;
    }
    return false;
  }

  hasPermission(key, editLanguage = null) {
    const upperKey = key.charAt(0).toUpperCase() + key.slice(1);
    const permissionMethod = `_hasPermissionTo${upperKey}`;

    // Check if there is a method named with the given key
    if (typeof this[permissionMethod] !== 'function') {
      return false;
    }

    // Retrun the permisson of the given method name
    return this[permissionMethod](editLanguage);
  }

  _hasPermissionToViewComment() {
    return this.isAdmin || this.isExpert;
  }

  // Experts can edit all the fields in all languages.
  // Translators and lectors can see all the fields in all the laguages
  // but can only edit the fields in their language.
  _hasPermissionToEditQuestion(editLanguage) {
    if (this.isExpert) {
      return true;
    }
    if (this.isTranslator || this.isLector) {
      return this.language === editLanguage;
    }
    return false;
  }
}

export { User };
