<template>
  <teleport v-if="isOpen" :to="mountPoint">
    <transition
      enter-active-class="transition-opacity transition-ease-out"
      leave-active-class="transition-opacity transition-ease-in"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="modal"
        :class="classModal"
        :aria-hidden="isOpen ? 'false' : 'true'"
        @keydown.esc="hideModal"
      >
        <div
          class="modal__backdrop"
          :class="classBackdrop"
          @click="hideModal"
        />

        <div class="modal__container" :class="classContainer">
          <div class="modal__header" :class="classHeader">
            <slot name="header">
              &nbsp;
            </slot>
            <button
              class="modal__btn-close"
              :class="classBtnClose"
              @click="hideModal"
            >
              <svg-icon name="close" class="svg-icon--sm" />
            </button>
          </div>

          <div class="modal__body" :class="classBody">
            <slot />
          </div>

          <div
            v-if="hasFooterSlot"
            class="modal__footer flex justify-between"
            :class="classFooter"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, useSlots } from 'vue';

const emit = defineEmits(['closeModal']);

const props = defineProps({
  /**
     * Specifies if the modal is opened or not
     */
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  /**
   * Optional class for the modal
   */
  classModal: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the backdrop
   */
  classBackdrop: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the modal container
   */
  classContainer: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the modal header
   */
  classHeader: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the modal body
   */
  classBody: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the modal footer
   */
  classFooter: {
    type: String,
    default: '',
  },
  /**
   * Optional class for the close button
   */
  classBtnClose: {
    type: String,
    default: '',
  },
  /**
   * Specifies where the modal is mounted
   */
  mountPoint: {
    type: String,
    default: 'body',
  }

})

const slots = useSlots(); 

const initiallyFocusedElement = ref('null');

const hasFooterSlot = computed(() => !!slots['footer']);

const hideModal = () => {
  emit('closeModal');
  refocusLastActive();
}

const refocusLastActive = () => {
  if (initiallyFocusedElement.value instanceof HTMLElement) {
    initiallyFocusedElement.value.focus();
  }
}

const saveLastActiveFocus = () => {
  initiallyFocusedElement.value = document.activeElement;
}

const handleKeyEvent = (event) => {
  if (event.code === 'Escape' && props.isOpen) {
    hideModal();
  }
}

watch(() => props.isOpen, (opened) => {
  if (opened) {
    saveLastActiveFocus();
  } else {
    refocusLastActive();
  }
});

onMounted(() => window.addEventListener('keyup', handleKeyEvent))

onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyEvent);
  document.body.style.top = 0;
})
</script>
