import { defineStore } from 'pinia';
import { User } from '@/models/User.model';

export const useAuthStore = defineStore('auth',  {
  state: () => ({
    /**
     * hydrate the state from the session if it exists otherwise null it
     */
    user: JSON.parse(sessionStorage.getItem('user'))
      ? new User(JSON.parse(sessionStorage.getItem('user')))
      : null,
    token: sessionStorage.getItem('token') || null,
    refreshToken: sessionStorage.getItem('refreshToken') || null,
    otpRequested: false,
    editLanguage: 'de',
  }),

  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn(state) {
      return state.token !== null;
    },
  },

  actions: {
    /**
     * Set the JWT token
     *
     * @param {string} token token
     */
    setToken(token) {
      this.token = token;
      sessionStorage.setItem('token', token);
    },

    /**
     * Set the Refresh token
     *
     * @param {string} token token
     */
    setRefreshToken(token) {
      this.refreshToken = token; 
      sessionStorage.setItem('refreshToken', token);
    },

    /**
     * Set the OTP state
     *
     * @param {boolean} otp otp
     */
    setOTPState(otp) {
      this.otpRequested = otp;
    },

    /**
     * Set the user state
     *
     * @param {User} user user
     */
    setUser(user) {
      this.user = user; 
      sessionStorage.setItem('user', JSON.stringify(user));
    },

    /**
     * Set the edit language
     *
     * @param {string} language language
     */
    setEditLanguage(language) {
      this.editLanguage = language;
    },

    /**
     * Remove the login information and effectively log the user out
     */
    removeAuth() {
      this.user = null;
      this.token = null;
      this.refreshToken = null;
      sessionStorage.clear();
    },
    setAuth(loggedUser) {
      // Set the user to the state
      const user = new User(loggedUser);
      this.setUser(user);

      // Set the tokens to the state
      this.setToken(loggedUser.access);
      this.setRefreshToken(loggedUser.refresh);
    },
    changeEditLanguage(language) {
      this.setEditLanguage(language);
    },
  },
});
