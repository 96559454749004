import List from '@/views/licensee/List.vue';

export default [
  {
    path: '/update',
    name: 'licenseeList',
    component: List,
    meta: {
      requiresAuth: true,
    },
  },
];
