<template>
  <div class="w-full md:w-1/3 pr-8 last:pr-0">
    <div class="bg-white rounded p-4 h-full text-sm">
      <h3
        v-if="answer.isCorrect"
        class="correct flex text-sm text-black uppercase font-medium border-b border-gray-800 pb-1 mb-3"
      >
        <svg-icon
          class="inline-block w-5 h-5 text-whitesvg-icon svg-icon--check-square mr-2"
          name="check-square"
        />
        {{ $t('questions.questionsEdit.correct') }}
      </h3>
      <h3
        v-else
        class="wrong flex text-sm text-black uppercase font-medium border-b border-gray-800 pb-1 mb-3"
      >
        <svg-icon
          class="inline-block w-5 h-5 text-whitesvg-icon svg-icon--times-square mr-2"
          name="times-square"
        />
        {{ $t('questions.questionsEdit.wrong') }}
      </h3>

      <div class="block text-black mt-2 font-medium">
        {{ $t('questions.questionsEdit.answer') }}
      </div>
      <div class="font-light text-black">{{ answer.textDe }}</div>

      <!-- TRANSLATION -->
      <div v-if="showTranslation">
        <div class="block text-black mt-4 font-medium">
          {{ $t('questions.questionsEdit.translation') }}
        </div>
        <div class="font-light text-black">{{ valueTranslated }}</div>
      </div>

      <span
        v-if="user.isTranslator"
        class="block text-black mt-4 font-medium mb-2"
      >
        {{ $t('questions.questionsEdit.translation') }}
      </span>
      <span v-else class="block text-black mt-4 font-medium mb-2">
        {{ $t('questions.questionsEdit.changes') }}
      </span>
      <Textarea
        :key="`${answer.id}_${editLanguage}_textArea`"
        v-model="content"
        :value="content"
        :disabled="disabled"
        label=""
        required
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useAuthStore } from '@/store/Auth';
import Textarea from '@/components/app/Textarea.vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  answer: {
    type: Object,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  valueTranslated: {
    type: String,
    required: true,
  },
  showTranslation: {
    type: Boolean,
    required: false,
  },
});

const authStore = useAuthStore();

const editLanguage = computed(() => authStore.editLanguage);
const user = computed(() => authStore.user);

const disabled = computed(() => {
  return (
    !authStore.user.hasPermission('editQuestion', editLanguage.value) ||
    props.question.inWorkgroupReview()
  );
});

const content = computed({
  get() {
    return props.modelValue;
  },

  set(value) {
    emits('update:modelValue', value);
  },
});
</script>

<style lang="scss" scoped>
.correct {
  @apply text-green-500;
}

.wrong {
  @apply text-red-900;
}

.svg-icon--check-square,
.svg-icon--times-square {
  position: relative;
  top: -2px;
}
</style>
