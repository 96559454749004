<template>
  <div>
    <Modal :is-open="modalOpen" @closeModal="modalOpen = false">
      <template #header>
        <h2>{{ $t('questions.questionsEdit.modal.titleAccept') }}</h2>
      </template>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('questions.questionsEdit.modal.textAccept')" />

      <template #footer>
        <button class="btn" @click.prevent="modalOpen = false">
          {{ $t('questions.questionsEdit.modal.dismiss') }}
        </button>
        <button
          class="btn btn--primary"
          @click.prevent="accept ? acceptQuestion() : declineQuestion()"
        >
          {{ $t('questions.questionsEdit.modal.accept') }}
        </button>
      </template>
    </Modal>

    <div
      class="flex w-full space-x-5 bg-white rounded p-4 h-full text-sm mb-10"
    >
      <div class="w-full md:w-2/3 pr-2">
        <textarea
          v-model="comment.message"
          :placeholder="$t('questions.questionsEdit.optionalComment')"
          class="block w-full p-3 pb-0 mb-3 bg-white border rounded outline-none"
          type="text"
        />
      </div>

      <div class="w-full md:w-1/3">
        <button class="btn btn--outline btn--big mr-5" @click="confirmDecline">
          {{ $t('questions.questionsEdit.declineQuestion') }}
        </button>
        <button class="btn btn--primary btn--big" @click="confirmAccept">
          {{ $t('questions.questionsEdit.acceptQuestion') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/store/Auth';
import { useQuestionStore } from '@/store/modules/Question';
import { useLoadingStore } from '@/store/modules/Loading';
import { approveQuestion } from '@/services/Questions.service';
import { toaster } from '@/globals/toaster.js';
import Modal from '@/components/app/Modal.vue';

const props = defineProps({
  decisionBy: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const { t } = useI18n();

const authStore = useAuthStore();
const loadingStore = useLoadingStore();
const questionStore = useQuestionStore();

const comment = ref({
  author: null,
  question: null,
  message: '',
});

const modalOpen = ref(false);
const accept = ref(null);

const confirmDecline = () => {
  modalOpen.value = true;
  accept.value = false;
};

const confirmAccept = () => {
  modalOpen.value = true;
  accept.value = true;
};

const declineQuestion = async () => {
  modalOpen.value = false;

  // Check if the user entered a comment as this is mandatory
  if (!comment.value.message.length) {
    toaster('error', t('questions.questionsEdit.astra.noCommentError'));
    return;
  }
  await save(false);
};

const acceptQuestion = async () => {
  modalOpen.value = false;
  await save(true);
};

const save = async (decision) => {
  // Try to create a comment and save the question
  loadingStore.addLoadingState();
  let newComment = null;

  try {
    if (comment.value.message) {
      newComment = await questionStore.createComment(comment.value);
    }
    await approveQuestion(
      decision,
      questionStore.currentQuestion.id,
      newComment,
      props.decisionBy
    );

    router.push({ name: 'questionList' });
    toaster('success', t('questions.questionsEdit.astra.success'));
  } catch {
    toaster('error', t('questions.questionsEdit.astra.error'));
  }
  loadingStore.removeLoadingState();
};

onBeforeMount(() => {
  comment.value.author = authStore.user.id;
  comment.value.question = questionStore.currentQuestion.id;
});
</script>
