import List from '@/views/question/List.vue';
import Detail from '@/views/question/Detail.vue';

export default [
  {
    path: '/',
    name: 'questionList',
    component: List,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/question/:id',
    name: 'questionDetail',
    component: Detail,
    meta: {
      requiresAuth: true,
    },
  },
];
