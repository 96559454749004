<template>
  <div>
    <h1>{{ $t('login.title') }}</h1>
    <LoginCredentialForm v-if="!authStore.otpRequested" @login="setCredentials" />
    <LoginTokenForm v-if="authStore.otpRequested" :credentials="credentials" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/store/Auth';
import LoginCredentialForm from '@/components/app/LoginCredentialForm.vue';
import LoginTokenForm from '@/components/app/LoginTokenForm.vue';

const authStore = useAuthStore();

const credentials = ref({
  email: '',
  password: '',
});

const setCredentials = (email, password) => {
  credentials.value.email = email;
  credentials.value.password = password;
}
</script>
