<template>
  <form>
    <forge-input
      v-model.trim="v$.token.$model"
      class="mt-4"
      class-input="w-full"
      :value="token"
      label="Login code"
      is-block
      required
      @blur="v$.token.$touch"
    >
      <validation-text v-if="v$.token.$error" class="mt-1">
        {{ $t('login.tokenRequired') }}
      </validation-text>
    </forge-input>

    <div class="flex items-center justify-between mt-6">
      <button
        class="btn btn--primary"
        :disabled="v$.$invalid"
        @click.prevent="doLogin"
      >
        {{ $t('login.submitButtonText') }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/store/Auth';
import { login } from '@/services/auth.js';
import ForgeInput from '@/components/app/Input.vue';
import ValidationText from '@/components/app/ValidationText.vue';

const props = defineProps({
  credentials: {
    type: Object,
    required: true
  }
});

const router = useRouter();
const { t } = useI18n();

const authStore = useAuthStore();

const token = ref('');

const rules = { token: { required }};
const v$ = useVuelidate(rules, { token });

const doLogin = async () => {
  try {
    const loginData = await login(
      props.credentials.email,
      props.credentials.password,
      token.value
    );
    authStore.setAuth(loginData);
    // todo: use property on user model. This did not work right away
    // for demo purposes this is a solid workarround
    if (loginData.updates && loginData.updates.length > 0) {
      router.push({ name: 'licenseeList' });
    } else {
      router.push({ name: 'questionList' });
    }
  } catch (e) {
    toaster('error', t('login.error'));
  }
}
</script>
