<template>
  <div v-if="readOnly" class="textarea-container text-sm font-medium">
    <span v-if="showRuleSetLangNotice" class="block text-black mt-4">{{
      $t('questions.questionsEdit.changesOnlyDE')
    }}</span>

    <span v-else class="block text-black mt-4">{{
      $t('questions.questionsEdit.changes')
    }}</span>

    <Textarea
      v-model="content"
      :value="content"
      disabled
      label=""
      required
      @input="updateInput"
    />
  </div>

  <div v-else class="textarea-container text-sm font-medium">
    <span class="block text-black mt-4">{{
      $t('questions.questionsEdit.changes')
    }}</span>

    <Textarea
      v-model="content"
      :value="content"
      label=""
      required
      @input="updateInput"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/store/Auth';
import Textarea from '@/components/app/Textarea.vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
});

const authStore = useAuthStore();

const content = ref(props.modelValue);

const readOnly = computed(
  () => authStore.editLanguage !== 'de' || props.question.inWorkgroupReview()
);
const showRuleSetLangNotice = computed(
  () => authStore.editLanguage !== 'de' && !props.question.inWorkgroupReview()
);

const updateInput = () => {
  emits('update:modelValue', content.value);
};
</script>

<style lang="postcss" scoped>
.textarea-container {
  margin-top: auto;
}
.block {
  @apply pt-2;
}
</style>
