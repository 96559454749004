import { computed } from 'vue';
import { isSameDay, format, isDate } from 'date-fns';
import { de, it, fr } from 'date-fns/locale';
import i18n from '@/i18n';

const locales = {
  de,
  it,
  fr,
};

export function showDateOrTime(value) {
  const currentLanguage = 'de';
  const translationTexts = computed(
    () => i18n.global.messages.value[currentLanguage]
  );
  const locale = locales[currentLanguage];

  // if its not a date or not a value return empty string
  if (!isDate(value)) return '';
  if (!value) return '';

  // if the date is today return only the hours followed by today
  if (isSameDay(value, new Date())) {
    return (
      format(value, 'p', { locale: locale }) +
      ', ' +
      translationTexts.value.general.today
    );
  }

  // otherwise return date
  return format(value, 'PP', { locale: locale });
}
